// extracted by mini-css-extract-plugin
export var leftRectangleSection = "leftRectangleSection-module--leftRectangleSection--MGiVA";
export var container = "leftRectangleSection-module--container--36IZQ";
export var imgSection = "leftRectangleSection-module--imgSection--lHJQP";
export var overlapText = "leftRectangleSection-module--overlapText--1_sQH";
export var textSection = "leftRectangleSection-module--textSection--3Jve-";
export var shapeBg = "leftRectangleSection-module--shapeBg--2iB4C";
export var iconContainer = "leftRectangleSection-module--iconContainer--O9TuY";
export var row1 = "leftRectangleSection-module--row1--MKCli";
export var row2 = "leftRectangleSection-module--row2--34coK";
export var iconWrapper = "leftRectangleSection-module--iconWrapper--37R7X";
export var imgTitle = "leftRectangleSection-module--imgTitle--2bBj8";