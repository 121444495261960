import React, {
  forwardRef,
  memo,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { Timeline, Tween, Reveal, ScrollTrigger, SplitChars } from "react-gsap"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import ExploseIcon from "src/images/revampImg/explose.png"
import VideoPoster from "src/images/videoPoster.png"

//@ts-ignore
import * as Styles from "./TopBannerSection.module.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useTranslation } from "react-i18next"

const TopBannerSection = () => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()
  const videoRef = useRef<any>(null)

  const videoObj = () =>
    fetch(
      `https://storage.googleapis.com/edm-instelar/2023_motex_edm/MOTEX_bg_video1.mp4`
    )
      .then(res => res.blob())
      .then(blob => {
        videoRef.current.src = URL.createObjectURL(blob)
      })

  useLayoutEffect(() => {
    videoObj()
    return () => {}
  }, [])

  return (
    <ScrollTrigger
      trigger="trigger"
      start="100px 100px"
      end="center"
      scrub={0.5}
      markers={true}
      pin={true}
    >
      <div className={Styles.topSection}>
        <video
          muted
          ref={videoRef}
          loop
          autoPlay
          poster={VideoPoster}
          preload="auto"
          // onLoadStart={() => {
          //   console.log("finished?")
          //   videoRef?.current?.play()
          // }}
          // style={{ height: "100vh", width: "100vw", objectFit: "cover" }}
          className={Styles.videoContainer}
        >
          <source />
          {/* <source src={BgVideo} /> */}
        </video>
        <div className={Styles.animationContainer}>
          <div className={Styles.wrapper}>
            <div className={Styles.textContainer}>
              <h1 className={Styles.animationBoldTitle}>
                {t("TXT_Motex_Cloud_ems")}
              </h1>

              <h1
                className={Styles.animationBoldSubTitle}
                style={{
                  display: "inline-block",
                }}
              >
                {t("TXT_Motex")}
              </h1>

              <div className={Styles.descWrapper}>
                <div className={Styles.row}>
                  <h3 className={Styles.animationDesc}>
                    {breakpoints.sm
                      ? t("TXT_Banner_desc1_M")
                      : t("TXT_Banner_desc1")}
                  </h3>
                  <h3 className={Styles.animationDesc}>
                    {t("TXT_Banner_desc2")}
                  </h3>
                </div>
                <div className={Styles.row}>
                  <h3 className={Styles.animationDesc}>
                    {breakpoints.sm
                      ? t("TXT_Banner_desc3_M")
                      : t("TXT_Banner_desc3")}
                  </h3>
                  <h3 className={Styles.animationDesc}>
                    {t("TXT_Banner_desc4")}
                  </h3>
                </div>
              </div>
            </div>

            <div className={Styles.knowMoreBtn}>
              <AnchorLink to="/#blueprintManagement" title="了解更多" stripHash>
                <p>{t("TXT_Banner_know_more")}</p>
                <img src={ExploseIcon} alt="explose" />
              </AnchorLink>
            </div>
          </div>
        </div>

        {/* <div className={Styles.animationContainer}>
          <Timeline target={<TargetWithNames />}>
            <Tween
            // from={{
            //   x: "0",
            // }}
            // to={{
            //   x: "100vw",
            //   y: "-100px",
            // }}
            // target="title1"
            // duration={2}
            />
            <Tween
            // target="desc"
            // to={{ scale: 0.3, opacity: 0, x: "-100vw" }}
            // stagger={{ from: "random" }}
            // duration={2}
            // ease="elastic.out(0.6, 0.8)"
            />
            <Tween
              // to={{ scale: 0.1, opacity: 0 }}
              // stagger={{ from: "center", amount: 0.5, grid: [5, 1] }}
              // ease="elastic.out(1, 0.5)"

              target="title2"
            />
            <Tween
              // from={{
              //   opacity: 1,
              // }}
              // to={{
              //   display: "none",
              //   opacity: 0,
              //   y: "-50px",
              //   marginTop: 0,
              // }}
              target="moreBtn"
            />
          </Timeline>
        </div> */}
      </div>
    </ScrollTrigger>
  )
}

export default memo(TopBannerSection)
