// extracted by mini-css-extract-plugin
export var blogContainer = "MotexBlogSection-module--blogContainer--7TiyR";
export var videoContainer = "MotexBlogSection-module--videoContainer--IU3hm";
export var blogWrapper = "MotexBlogSection-module--blogWrapper--2SP_8";
export var blogTitle = "MotexBlogSection-module--blogTitle--3qiST";
export var blogDesc = "MotexBlogSection-module--blogDesc--3Dadk";
export var gridContainer = "MotexBlogSection-module--gridContainer--3bixi";
export var card = "MotexBlogSection-module--card--3pS3Q";
export var thumbnail = "MotexBlogSection-module--thumbnail--2FlIr";
export var wrapper = "MotexBlogSection-module--wrapper--10uyk";
export var tagAndDateRow = "MotexBlogSection-module--tagAndDateRow--tA3hC";
export var tag = "MotexBlogSection-module--tag--GAFEC";
export var date = "MotexBlogSection-module--date--2HzZm";
export var title = "MotexBlogSection-module--title--1JQ2y";
export var desc = "MotexBlogSection-module--desc--3MV9h";
export var knowMoreBtn = "MotexBlogSection-module--knowMoreBtn--1dONG";
export var checkMoreBtn = "MotexBlogSection-module--checkMoreBtn--2xZnQ";
export var divider = "MotexBlogSection-module--divider--3b-qq";