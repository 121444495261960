import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { Timeline, Tween, Reveal, ScrollTrigger, SplitChars } from "react-gsap"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Classnames from "classnames"
import ReactPlayer from "react-player"
import ModalVideo from "react-modal-video"

import BGImage from "src/images/revampImg/blueprint_bg1.png"
// import BGImage from "src/images/revampImg/das.png"
import BlueprintIndexImg from "src/images/revampImg/blueprint_bg2.png"
import MarkupToolImg from "src/images/revampImg/blueprint_bg3.png"
import VersionCompareImg from "src/images/revampImg/blueprint_bg4.png"
import BlueprintDiscussImg from "src/images/revampImg/blueprint_bg5.png"
import UploadAndStoreImg from "src/images/revampImg/blueprint_bg6.png"
import PlayVideoBtn from "src/images/revampImg/playVideoBtn.png"
import CloseIcon from "src/images/revampImg/closeIcon.png"

//@ts-ignore
import * as Styles from "./BlueprintManagementSection.module.scss"

import { useTranslation } from "react-i18next"

const BlueprintManagementSection = () => {
  const { t } = useTranslation()
  const [containerWidth, setContainerWidth] = useState(0)
  const [isOpen, setOpen] = useState<{ isOpen: boolean; youtubeID: string }>({
    isOpen: false,
    youtubeID: "",
  })
  const containerRef = useRef<HTMLDivElement>(null)
  const breakpoints = useBreakpoint()

  useEffect(() => {
    if (containerRef?.current) {
      setContainerWidth(containerRef?.current?.offsetWidth)
    }
    return () => {}
  }, [containerRef])
  //   console.log(containerRef?.current?.offsetWidth)
  return (
    <section ref={containerRef} className={Styles.blueprintSection}>
      {/* <ScrollTrigger
        // start="-200px center"
        // end="auto bottom"
        scrub={0.5}
        markers
      > */}
      <Reveal repeat>
        {/* banner row */}
        <div className={Styles.banner}>
          <Tween
            from={{
              opacity: 0,
            }}
            to={{
              opacity: 1,
            }}
            duration={2}
          >
            <div className={Styles.left}>
              <h3 className={Styles.title}>{t("TXT_Blueprint_management")}</h3>
              <p> {t("TXT_Banner_Desc")}</p>
            </div>
          </Tween>
          <div className={Styles.right}>
            <img
              src={BGImage}
              alt="background image"
              // className={Styles.bgSection}
            />
            {!breakpoints.sm && <div className={Styles.leftTriangle}></div>}
            {!breakpoints.sm && <div className={Styles.rightTriangle}></div>}

            {/* <div className={Styles.parallelogram}></div> */}
          </div>
        </div>
      </Reveal>

      <Reveal repeat>
        {/* blueprint index row */}
        <div className={Styles.flexContainer}>
          <div className={Styles.leftText}>
            <h3>{t("TXT_Blueprint_index")}</h3>
            <ul>
              <li>{t("TXT_Blueprint_index_desc1")}</li>
              <li>{t("TXT_Blueprint_index_desc2")}</li>
              <li>{t("TXT_Blueprint_index_desc3")}</li>
            </ul>
            <div
              className={Styles.videoBtn}
              onClick={() =>
                setOpen({ isOpen: true, youtubeID: "m8h41JfY4i4" })
              }
            >
              <img src={PlayVideoBtn} alt="watch video on youtube" />
              <div className={Styles.divider}></div>
              {t("TXT_Video_Intro")}
            </div>
          </div>
          <Tween
            from={{
              x: 100,
              opacity: 0,
            }}
          >
            <div className={Styles.rightImg}>
              <img src={BlueprintIndexImg} alt="圖則檢索" />
            </div>
          </Tween>
        </div>
      </Reveal>

      <Reveal repeat>
        {/* markup tool row */}
        <div
          className={Classnames(Styles.flexContainer, Styles.end)}
          // className={[Styles.flexContainer,Styles.end]}
        >
          <Tween
            from={{
              x: -100,
              opacity: 0,
            }}
          >
            <div className={Styles.leftImg}>
              <img src={MarkupToolImg} alt="標註工具" />
            </div>
          </Tween>
          <div className={Styles.rightText}>
            <h3>{t("TXT_Markup_tool")}</h3>
            <ul>
              <li>{t("TXT_Markup_tool_desc1")}</li>
              <li>{t("TXT_Markup_tool_desc2")}</li>
              <li>{t("TXT_Markup_tool_desc3")}</li>
            </ul>
            <div
              className={Styles.videoBtn}
              onClick={() =>
                setOpen({ isOpen: true, youtubeID: "IFbz-BNLi84" })
              }
            >
              <img src={PlayVideoBtn} alt="watch video on youtube" />
              <div className={Styles.divider}></div>
              {t("TXT_Video_Intro")}
            </div>
          </div>
        </div>
      </Reveal>

      <Reveal repeat>
        {/* version compare row */}
        <div className={Styles.flexContainer}>
          <div className={Styles.leftText}>
            <h3>{t("TXT_Version_compare")}</h3>
            <ul>
              <li>{t("TXT_Version_compare_desc1")}</li>
              <li>{t("TXT_Version_compare_desc2")}</li>
              <li>{t("TXT_Version_compare_desc3")}</li>
            </ul>
            <div
              className={Styles.videoBtn}
              onClick={() =>
                setOpen({ isOpen: true, youtubeID: "HspEsuyVpKQ" })
              }
            >
              <img src={PlayVideoBtn} alt="watch video on youtube" />
              <div className={Styles.divider}></div>
              {t("TXT_Video_Intro")}
            </div>
          </div>
          <Tween
            from={{
              x: 100,
              opacity: 0,
            }}
          >
            <div className={Styles.rightImg}>
              <img src={VersionCompareImg} alt="版本比對" />
            </div>
          </Tween>
        </div>
      </Reveal>

      <Reveal repeat>
        {/* blueprint discuss row */}
        <div className={Classnames(Styles.flexContainer, Styles.end)}>
          <Tween
            from={{
              x: -100,
              opacity: 0,
            }}
          >
            <div className={Styles.leftImg}>
              <img src={BlueprintDiscussImg} alt="圖則討論" />
            </div>
          </Tween>
          <div className={Styles.rightText}>
            <h3>{t("TXT_Blueprint_discuss")}</h3>
            <ul>
              <li>{t("TXT_Blueprint_discuss_desc1")}</li>
              <li>{t("TXT_Blueprint_discuss_desc2")}</li>
            </ul>
            {/* <div
              className={Styles.videoBtn}
              onClick={() =>
                setOpen({ isOpen: true, youtubeID: "L61p2uyiMSo" })
              }
            >
              <img src={PlayVideoBtn} alt="watch video on youtube" />
              <div className={Styles.divider}></div>
              {t("TXT_Video_Intro")}
            </div> */}
          </div>
        </div>
      </Reveal>

      <Reveal repeat>
        {/* upload and store row */}
        <div className={Styles.flexContainer}>
          <div className={Styles.leftText}>
            <h3>{t("TXT_Upload_and_store")}</h3>
            <ul>
              <li>{t("TXT_Upload_and_store_desc1")}</li>
              <li>{t("TXT_Upload_and_store_desc2")}</li>
            </ul>
            <div
              className={Styles.videoBtn}
              onClick={() =>
                setOpen({ isOpen: true, youtubeID: "xPp70DAWwFY" })
              }
            >
              <img src={PlayVideoBtn} alt="watch video on youtube" />
              <div className={Styles.divider}></div>
              {t("TXT_Video_Intro")}
            </div>
          </div>
          <Tween
            from={{
              x: 100,
              opacity: 0,
            }}
          >
            <div className={Styles.rightImg}>
              <img src={UploadAndStoreImg} alt="上傳及儲存" />
            </div>
          </Tween>
        </div>
      </Reveal>
      {/* </ScrollTrigger> */}

      <ModalVideo
        channel="youtube"
        isOpen={isOpen.isOpen}
        videoId={isOpen.youtubeID}
        onClose={() => setOpen({ isOpen: false, youtubeID: "" })}
      />
    </section>
  )
}

export default BlueprintManagementSection
