// extracted by mini-css-extract-plugin
export var topSection = "TopBannerSection-module--topSection--3OL4I";
export var videoContainer = "TopBannerSection-module--videoContainer--q-iII";
export var animationContainer = "TopBannerSection-module--animationContainer--3B1Gb";
export var wrapper = "TopBannerSection-module--wrapper--37EY4";
export var textContainer = "TopBannerSection-module--textContainer--1heHN";
export var animationBoldTitle = "TopBannerSection-module--animationBoldTitle--jxCCX";
export var animationBoldSubTitle = "TopBannerSection-module--animationBoldSubTitle--1jDVQ";
export var descWrapper = "TopBannerSection-module--descWrapper--1g5bA";
export var row = "TopBannerSection-module--row--29nSd";
export var animationDesc = "TopBannerSection-module--animationDesc--3ZEJf";
export var knowMoreBtn = "TopBannerSection-module--knowMoreBtn--1LYJo";