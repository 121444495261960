import i18next from "i18next"
import React from "react"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import { Router, Route, Routes } from "react-router-dom"

import NewHomePage from "src/components/newHomePage/NewHomePage" // old version
import BlogArticle from "src/components/newHomePage/motexBlog/BlogArticle"
import "../i18n.js"
//@ts-ignore
import Thumbnail from "src/images/thumbnail.jpg"
import { graphql } from "gatsby"

const IndexPage = ({ data }: any) => {
  const [show, setShow] = React.useState(false)
  React.useEffect(() => {
    setShow(true)
  }, [])

  useEffect(() => {
    const langCode = localStorage.getItem("langCode")
    i18next.changeLanguage(langCode ?? "tc")

    return () => {}
  }, [])

  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <link rel="canonical" href="https://motex.io/" />
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <meta name="robots" content={data.site.siteMetadata.robots} />
        <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta
          property="og:description"
          content={data.site.siteMetadata.description}
        />
        <meta property="og:type" content="website" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1"
        />
      </Helmet>
      <NewHomePage />
      {/* {show ? (
        <Routes>
          <Route path="/" element={<NewHomePage />} />
          <Route path="/article/:id" element={<BlogArticle />} />
        </Routes>
      ) : null} */}
    </>
  )
}

export const query = graphql`
  query IndexQueryEn {
    site {
      siteMetadata {
        title
        description
        siteTitle
        siteTitleShort
        siteDescription
        siteUrl
        keywords
        robots
      }
    }
  }
`

export default IndexPage
