import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"

import { useBreakpoint } from "gatsby-plugin-breakpoints"

import scrollTo from "gatsby-plugin-smoothscroll"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { useQueryParam, StringParam } from "use-query-params"
import axios from "axios"

//@ts-ignore
import * as Styles from "./NewHomePage.module.scss"

import { useLocation, useNavigate, useParams } from "react-router-dom"
import InstelarFooterLogo from "src/images/revampImg/instelar_footer_logo.png"
import LinkedInIcon from "src/images/revampImg/linkedin.png"
import FbIcon from "src/images/revampImg/facebook.png"
import IgIcon from "src/images/revampImg/ig.png"
import YoutubeIcon from "src/images/revampImg/youtube.png"
import GoTopIcon from "src/images/revampImg/upArrow.png"

import { useResizeDetector } from "react-resize-detector"
import { navigate } from "gatsby"
import NewNavBar from "./NewNavBar"

import TopBannerSection from "./TopBannerSection"
import BlueprintManagementSection from "./blueprintManagement/BlueprintManagementSection"
import MissionManagementSection from "./missionManagement/MissionManagementSection"
import InstallationManagementSection from "./installationManagement/InstallationManagementSection"
import AssetsManagementSection from "./assetsManagement/AssetsManagementSection"
import LogisticsTrackingSection from "./logisticsTracking/LogisticsTrackingSection"
import ContactUsBtn from "./ContactUsBtn"
import { useTranslation } from "react-i18next"
import MotexBlog from "./motexBlog/MotexBlogSection"
import Footer from "./Footer"

const NewHomePage = () => {
  const breakpoints = useBreakpoint()
  const { t } = useTranslation()
  const [localWidth, setlocalWidth] = useState<number | undefined>(undefined)
  const [companyId] = useQueryParam("companyId", StringParam)
  // const [_preSection, setPreSection] = useState('')

  // useEffect(() => {
  //   if (!section || !companyId) return
  //   axios
  //     .post(
  //       "https://asia-east2-instelar-295406.cloudfunctions.net/gcf-nodejs-prod-logAccess",
  //       { feature: section.replace("#", ""), companyId: companyId }
  //     )
  //     .then(res => {
  //       console.log("res", res)
  //     })
  //     .catch(error => {
  //       console.log("error", error)
  //     })
  // }, [])

  const { width, ref } = useResizeDetector()

  useEffect(() => {
    setlocalWidth(prev => {
      if (!width) return undefined
      if (!prev) return width
      if ((prev < 770 && width > 770) || (prev > 770 && width < 770))
        location.reload()
      return width
    })
  }, [width])

  const backToTop = () => {
    window?.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  const videoUrl = `https://storage.googleapis.com/edm-instelar/2023_motex_edm/MOTEX_bg_video.mp4`

  return (
    <>
      <div id="mainTop"></div>
      <NewNavBar />

      <main ref={ref} className={Styles.main}>
        {/* <NewNavBar navAction={(dest: string) => customScrollTo(dest)} /> */}

        <TopBannerSection />

        <div id={"blueprintManagement"}>
          <BlueprintManagementSection />
        </div>

        <div id={"missionManagement"}>
          <MissionManagementSection />
        </div>

        <div id={"installationManagement"}>
          <InstallationManagementSection />
        </div>

        <div id={"assetsManagement"}>
          <AssetsManagementSection />
        </div>

        <div id={"logisticsTracking"}>
          <LogisticsTrackingSection />
        </div>

        <div id={"motexBlog"}>
          <MotexBlog />
        </div>

        <ContactUsBtn />
      </main>
      <Footer scrollToTop={() => backToTop()} />
    </>
  )
}

export default NewHomePage
