import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { Timeline, Tween, Reveal, ScrollTrigger, SplitChars } from "react-gsap"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Classnames from "classnames"

import LogisticsBg from "src/images/revampImg/logistics_bg1.png"
import LeftRectangle from "src/images/revampImg/LeftRectangle.png"
import LogisticsIcon1 from "src/images/revampImg/logistics_icon1.png"
import LogisticsIcon2 from "src/images/revampImg/logistics_icon2.png"
import LogisticsIcon3 from "src/images/revampImg/logistics_icon3.png"

//@ts-ignore
import * as Styles from "../leftRectangleSection.module.scss"

import { useTranslation } from "react-i18next"

const LogisticsTrackingSection = () => {
  const { t } = useTranslation()
  const [containerWidth, setContainerWidth] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <section className={Styles.leftRectangleSection}>
      <Reveal repeat>
        <div className={Styles.container}>
          <Tween
          // from={{
          //   opacity: 0,
          // }}
          // to={{
          //   opacity: 1,
          // }}
          // duration={2}
          >
            <div className={Styles.textSection}>
              <img
                src={LeftRectangle}
                className={Styles.shapeBg}
                alt="rectangle background image"
              />
              <div className={Styles.iconContainer}>
                <div className={Styles.row1}>
                  <IconWithText
                    imgPath={LogisticsIcon1}
                    text={t("TXT_Logistic_tracking_text1")}
                    imgAlt="logistics tracking icon1"
                  />
                  <IconWithText
                    imgPath={LogisticsIcon2}
                    text={t("TXT_Logistic_tracking_text2")}
                    imgAlt="logistics tracking icon2"
                  />
                </div>

                <div className={Styles.row2}>
                  <IconWithText
                    imgPath={LogisticsIcon3}
                    text={t("TXT_Logistic_tracking_text3")}
                    imgAlt="logistics tracking icon3"
                  />
                </div>
              </div>
            </div>
          </Tween>
          <div className={Styles.imgSection}>
            <img src={LogisticsBg} alt="Installation management" />
            <div className={Styles.overlapText}>
              <h3>{t("TXT_Logistic_tracking_heading")}</h3>
              <h4>{t("TXT_Logistic_tracking_desc1")}</h4>
            </div>
          </div>
        </div>
      </Reveal>
    </section>
  )
}

const IconWithText = ({
  imgPath,
  text,
  imgAlt,
}: {
  imgPath: string
  text: string
  imgAlt?: string
}) => {
  const { t } = useTranslation()
  return (
    <div className={Styles.iconWrapper}>
      <img src={imgPath} alt={imgAlt} />
      <div className={Styles.imgTitle}>{text}</div>
    </div>
  )
}

export default LogisticsTrackingSection
