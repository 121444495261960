import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Timeline, Tween, Reveal, ScrollTrigger } from 'react-gsap'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Dotdotdot from 'react-dotdotdot'
import Classnames from 'classnames'
import NoImg from '../../../images/revampImg/noImg.png'
import Calendar from '../../../images/revampImg/calendar.png'
import NextArrowRed from '../../../images/revampImg/nextArrowRed.png'
import ChatText from '../../../images/revampImg/chatText.png'
import VideoPoster from 'src/images/videoPoster.png'

//@ts-ignore
import * as Styles from './MotexBlogSection.module.scss'

import { useTranslation } from 'react-i18next'

import { Link } from 'gatsby'
import axios from 'axios'
import moment from 'moment'

const MotexBlogSection = () => {
  const breakpoints = useBreakpoint()
  const videoRef = useRef<any>(null)
  const { t } = useTranslation()
  const [articleList, setArticleList] = useState<any>([])

  const videoObj = () =>
    fetch(`https://storage.googleapis.com/edm-instelar/2023_motex_edm/MOTEX_bg_video1.mp4`)
      .then(res => res.blob())
      .then(blob => {
        if (videoRef.current) videoRef.current.src = URL.createObjectURL(blob)
      })

  useLayoutEffect(() => {
    videoObj()
    getList()
    return () => {}
  }, [])

  const getBlogList = async () => {
    const apiEndPoint = `https://app.motex.io/api/blog/getList`
    try {
      const res = await axios.post(`${apiEndPoint}`, {
          limit: 3,
          sort: 'latest',
      })

      return res.data.blogs
    } catch (error) {
      console.log(`error`)
      console.log(error)
    }
  }

  const getList = async () => {
    const blogData = await getBlogList()
    const displayBlogData = blogData?.map(p => {
      return {
        id: p.id,
        title: p.title ?? '-',
        desc: p.description ?? '-',
        content: p.content ?? '-',
        tag: p.type,
        date: moment(p.createdAt).format('DD-MM-YYYY'),
        thumbnail: p.coverImage ?? NoImg,
      }
    })

    setArticleList(displayBlogData)
  }

  return (
    <div className={Styles.blogContainer}>
      {!breakpoints.sm && (
        <video
          muted
          autoPlay
          loop
          poster={VideoPoster}
          ref={videoRef}
          // style={{ height: "100vh", width: "100vw", objectFit: "cover" }}
          className={Styles.videoContainer}
        >
          <source />
          {/* <source src={BgVideo} /> */}
        </video>
      )}
      <div className={Styles.blogWrapper}>
        <div className={Styles.blogTitle}>{t('TXT_Web_blog')}</div>
        {/* <div className={Styles.blogTitle}>Coming soon</div> */}
        <div className={Styles.blogDesc}>
          {t('TXT_Blog_desc1')}
          <br></br>
          {t('TXT_Blog_desc2')}
        </div>
        <div className={Styles.gridContainer}>
          {articleList?.map((acticle, i) => {
            return <BlogCard articleData={acticle} key={i} />
          })}
        </div>
        <Link to="/blog" className={Styles.checkMoreBtn}>
          <img src={ChatText} alt="chat icon" />
          <div className={Styles.divider}></div>
          {t('TXT_Blog_view_more_blog')}
        </Link>
      </div>
    </div>
  )
}

const BlogCard = ({ articleData }: any) => {
  const breakpoints = useBreakpoint()
  const { t } = useTranslation()
  return (
    <div className={Styles.card}>
      <div className={Styles.thumbnail}>
        <img src={articleData.thumbnail} style={{ objectFit: 'contain', width: '100%', height: '100%' }}></img>
      </div>

      <div className={Styles.wrapper}>
        <div className={Styles.tagAndDateRow}>
          <div className={Styles.tag}>{t(`TXT_Tag_${articleData.tag}`) ?? '-'}</div>
          <div className={Styles.date}>
            <img src={Calendar} alt="calendar"></img>
            {articleData.date ?? '-'}
          </div>
        </div>
        <div className={Styles.title}>
          <Dotdotdot clamp={5}>
            <p> {articleData.title}</p>
          </Dotdotdot>
        </div>

        <div className={Styles.desc}>
          <Dotdotdot clamp={5}>
            {/* <p dangerouslySetInnerHTML={{ __html: articleData?.desc ?? '-' }} /> */}
            <p> {articleData.desc}</p>
          </Dotdotdot>
        </div>

        <Link className={Styles.knowMoreBtn} to={`/blog/${articleData.title}`} state={{ myProp: articleData }}>
          {t('TXT_Banner_know_more')}

          <img src={NextArrowRed} alt="know more" />
        </Link>
      </div>
    </div>
  )
}

export default MotexBlogSection
