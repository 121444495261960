// extracted by mini-css-extract-plugin
export var rightRectangleSection = "rightRectangleSection-module--rightRectangleSection--3A2_o";
export var container = "rightRectangleSection-module--container--QFAAC";
export var imgSection = "rightRectangleSection-module--imgSection--2msJZ";
export var overlapText = "rightRectangleSection-module--overlapText--bRFDf";
export var textSection = "rightRectangleSection-module--textSection--2lySY";
export var shapeBg = "rightRectangleSection-module--shapeBg--vaXQ3";
export var iconContainer = "rightRectangleSection-module--iconContainer--3_FAG";
export var row1 = "rightRectangleSection-module--row1--1IfXI";
export var row2 = "rightRectangleSection-module--row2--3VhtH";
export var iconWrapper = "rightRectangleSection-module--iconWrapper--1KKuj";
export var imgTitle = "rightRectangleSection-module--imgTitle--2FC8i";
export var withTwoItems = "rightRectangleSection-module--withTwoItems--1jvyS";
export var withThreeItems = "rightRectangleSection-module--withThreeItems--2rNRd";