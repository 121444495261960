// extracted by mini-css-extract-plugin
export var blueprintSection = "BlueprintManagementSection-module--blueprintSection--2dU7e";
export var banner = "BlueprintManagementSection-module--banner--2ke_6";
export var left = "BlueprintManagementSection-module--left--22SbE";
export var title = "BlueprintManagementSection-module--title--1uk4d";
export var right = "BlueprintManagementSection-module--right--3AU-r";
export var leftTriangle = "BlueprintManagementSection-module--leftTriangle--2ivqX";
export var rightTriangle = "BlueprintManagementSection-module--rightTriangle--ZvlSc";
export var flexContainer = "BlueprintManagementSection-module--flexContainer--2wHH3";
export var leftText = "BlueprintManagementSection-module--leftText--rmo8A";
export var rightText = "BlueprintManagementSection-module--rightText--33OeJ";
export var videoBtn = "BlueprintManagementSection-module--videoBtn--1ZKcv";
export var divider = "BlueprintManagementSection-module--divider--12Jji";
export var videoContainer = "BlueprintManagementSection-module--videoContainer--K5Mcs";
export var closeBtn = "BlueprintManagementSection-module--closeBtn--4v0Ua";
export var rightImg = "BlueprintManagementSection-module--rightImg--t5p2R";
export var leftImg = "BlueprintManagementSection-module--leftImg--qiVZG";
export var end = "BlueprintManagementSection-module--end--2oKk3";